<script lang="ts" setup>
const { open } = useAppModals();
const { isMobile } = useDevice();

const isAnimation = ref(false);
const dailyWheelWidget = ref<HTMLElement | null>(null);

const wheelSize = computed(() => (isMobile ? "65px" : "95px"));

onMounted(() => {
	const observer = new IntersectionObserver(
		(el) => {
			isAnimation.value = el[0].isIntersecting;
		},
		{ threshold: 0.7 }
	);
	if (dailyWheelWidget.value) {
		observer.observe(dailyWheelWidget.value);
	}
});
</script>

<template>
	<div
		ref="dailyWheelWidget"
		class="daily-wheel-widget"
		data-tid="daily-wheel-widget"
		@click="open('LazyOModalDailyWheel')"
	>
		<NuxtImg
			src="/nuxt-img/wheel-of-fortune/leaves-widget.png"
			width="240"
			heigh="240"
			format="avif"
			loading="lazy"
			class="leaves"
		/>
		<MWheelOfFortuneAnimated :size="wheelSize" />
	</div>
</template>

<style scoped lang="scss">
.widget-fixed-side {
	position: fixed;
	z-index: 20;
	right: 0;
	bottom: 234px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			bottom: 100px;
		}
	}

	@include media-breakpoint-up(lg) {
		bottom: 25vh;
		& > * {
			cursor: pointer;
		}
	}
}

.daily-wheel-widget {
	width: 95px;
	right: -50%;
	position: relative;
	transform: translate(16px, 0);
	animation: animation 16.5s infinite linear;
	pointer-events: auto;
	@include media-breakpoint-up(lg) {
		width: 120px;
		transform: translate(10px, 0);
		cursor: pointer;
	}
}

.leaves {
	position: absolute;
	width: 120px;
	height: 120px;
	top: -20px;
	left: -20px;
	z-index: 0;

	@include media-breakpoint-down(lg) {
		width: 80px;
		height: 80px;
		top: -10px;
		left: -10px;
	}
}
@keyframes animation {
	0%,
	42% {
		right: -50%;
	}
	2%,
	40% {
		right: -30%;
	}
}
</style>
